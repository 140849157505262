import { createIcon } from "@illa-design/react"

const HydraIcon = createIcon({
  title: "HydraIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M21.9926 3.51953H16.9051V8.76065C16.9051 10.2392 15.7605 11.4581 14.3137 11.5799C14.6476 10.7161 14.8278 9.77814 14.8384 8.80305V8.23071H9.75092V8.75535C9.75092 10.2604 8.56915 11.4952 7.09061 11.5852V3.51953H2.00317V20.483H7.09061V16.6833H14.0805C15.0768 16.6833 16.0307 16.4978 16.9157 16.1586V20.483H22.0032V8.76065V8.23601V3.51953H21.9926ZM6.91573 15.6287H6.03603V19.4284H3.05246V4.56882H6.03603V12.6451H6.91573C8.88182 12.6451 10.5087 11.1825 10.7631 9.2853H13.7626C13.4923 12.8253 10.514 15.6287 6.91573 15.6287ZM20.938 19.4284H17.9544V15.6552C19.1945 14.9557 20.2279 13.9276 20.938 12.6928V19.4284ZM20.938 8.76065V8.80305C20.9168 12.5656 17.8325 15.6287 14.0699 15.6287H10.8479C12.0827 14.9186 13.1055 13.8852 13.8103 12.6451H14.0699C16.2109 12.6451 17.9544 10.9016 17.9544 8.76065V4.56882H20.938V8.76065Z"
      fill="#EF7A2A"
    />
  ),
})

HydraIcon.displayName = "HydraIcon"
export default HydraIcon
