import {
  authCloudRequest,
  notNeedAuthCloudRequest,
} from "@illa-public/illa-net"
import { BaseUserInfo, CurrentUserInfo } from "@illa-public/public-types"
import { OAUTH_REDIRECT_URL } from "@illa-public/sso-module"

export const checkIfUserNeedsPasswordReset = (userEmail?: string) => {
  return authCloudRequest<{
    doesUserNeedResetPassword: boolean
  }>({
    url: `/auth/byEmail/${userEmail}/needResetPassword`,
    method: "GET",
  })
}

interface SignInRequestBody {
  email: string
  password: string
}
export const fetchSignIn = (data?: SignInRequestBody) => {
  return notNeedAuthCloudRequest<CurrentUserInfo>({
    url: "/auth/signin",
    method: "POST",
    data,
  })
}

export const fetchCancelLinked = (oauthAgency: "github" | "google") => {
  return authCloudRequest({
    url: `/users/oauth/${oauthAgency}`,
    method: "DELETE",
  })
}

interface fetchOAuthURIResponse {
  uri: string
}

export const fetchOAuthURI = (
  oauthAgency: "github" | "google",
  landing: "signin" | "signup" | "connect",
  redirectURI?: string,
) => {
  return authCloudRequest<fetchOAuthURIResponse>({
    url: `/oauth/${oauthAgency}/uri/redirectTo/${encodeURIComponent(
      redirectURI ?? OAUTH_REDIRECT_URL,
    )}/landing/${landing}`,
    method: "GET",
  })
}

interface forgetPasswordRequestBody {
  email: string
  verificationToken: string
  newPassword: string
}

export const fetchUpdatePasswordFromForgot = (
  data: forgetPasswordRequestBody,
) => {
  return authCloudRequest({
    method: "POST",
    url: "/auth/forgetPassword",
    data,
  })
}

interface IFetchSignInRequestData {
  nickname: string
  email: string
  verificationToken: string
  password: string
  isSubscribed: boolean
  inviteToken?: string | null
  language: string
}

export const fetchSignUp = (data: IFetchSignInRequestData) => {
  return authCloudRequest<CurrentUserInfo>({
    method: "POST",
    url: "/auth/signup",
    data: data,
  })
}

interface IFetchExchangeTokenRequestData {
  code: string
  state: string
}

export const fetchExchangeToken = (
  oauthAgency: "github" | "google",
  data: IFetchExchangeTokenRequestData,
) => {
  return authCloudRequest<BaseUserInfo>({
    url: `/oauth/${oauthAgency}/exchange`,
    method: "POST",
    data,
  })
}

export const fetchLogout = async (token: string) => {
  return await authCloudRequest({
    method: "POST",
    url: "/users/logout",
    headers: {
      Authorization: token,
    },
  })
}
