import { css } from "@emotion/react"

export const browserPreviewContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
`

export const toolbarHeaderStyle = css`
  width: 100%;
  flex: none;
  border-radius: 8px 8px 0px 0px;
  background: #dde1e7;
  display: flex;
  align-items: center;
  gap: 2px;
  padding-left: 13px;
  padding-top: 8px;
`

export const controlGroupStyle = css`
  display: flex;
  gap: 8px;
`

const baseControlStyle = css`
  height: 12px;
  width: 12px;
  border-radius: 50%;
`

export const redControlStyle = css`
  ${baseControlStyle};
  background: #ff504f;
`

export const yellowControlStyle = css`
  ${baseControlStyle};
  background: #ffbe00;
`

export const greenControlStyle = css`
  ${baseControlStyle};
  background: #00ce19;
`

export const tabContainerStyle = css`
  display: flex;
  align-items: flex-end;
`

export const tabTitleStyle = css`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 9px;
  border-radius: 8px 8px 0px 0px;
  background-color: white;
`

export const urlControlContainerStyle = css`
  width: 100%;
  height: 38px;
  background-color: white;
  padding: 5px 16px;
`

export const urlControlStyle = css`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background-color: #f1f3f4;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 6px 11px;
`

export const metaIconStyle = css`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`

export const urlStyle = css`
  color: #202124;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  margin: 0;
`

export const tableNameStyle = css`
  color: #484c4f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: 0;
  max-width: 203px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const logoContainerStyle = css`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
