import { initMulDataGridLicense } from "@illa-public/create-app"
import { ILLAMixpanel } from "@illa-public/mixpanel-utils"
import { initDateReport } from "@illa-public/utils"
import * as Sentry from "@sentry/react"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import "@/api/http/base"
import "@/i18n"
import { store } from "@/store"
import "@/utils/dayjs"
import App from "./App"

if (
  import.meta.env.ILLA_APP_ENV &&
  import.meta.env.ILLA_APP_ENV === "production" &&
  import.meta.env.ILLA_SENTRY_SERVER_API
) {
  Sentry.init({
    dsn: import.meta.env.ILLA_SENTRY_SERVER_API,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.ILLA_APP_ENV,
    tracesSampleRate: 1.0,
    release: `illa-cloud@${import.meta.env.ILLA_APP_VERSION}`,
  })
}
initMulDataGridLicense(
  "809607b05e26e7b6402851096d8865bfTz03NTU0NCxFPTE3Mjc0NDUwMTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
)

ILLAMixpanel.setDeviceID()
initDateReport()

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
