import { FC, memo } from "react"
import DomainForm from "../Components/DomainForm"
import { customDomainContainerStyle, titleStyle } from "./style"

const MobileCustomDomain: FC = () => {
  return (
    <div css={customDomainContainerStyle}>
      <h1 css={titleStyle}>Custom Domain</h1>
      <DomainForm />
    </div>
  )
}

export default memo(MobileCustomDomain)
