import { PCDashboardLoading } from "@illa-public/cloud-dashboard-layout"
import { LazyExoticComponent, ReactNode, Suspense, lazy } from "react"
import { Navigate } from "react-router-dom"
import { FullPageLoading } from "@/components/FullPageLoading"
import { Status403 } from "@/page/status/403"
import { Status404 } from "@/page/status/404"
import { Status500 } from "@/page/status/500"
import { ILLARoutesObject } from "@/router/interface"
import { SettingLazyLoad } from "@/router/lazyLoad/SettingLazyLoad"
import Branding from "../page/setting/team/Branding/Module/CustomDomain"
import RedirectExpired from "../page/status/redirectExpired"
import { checkNeedResetPwdLoader } from "./loader/checkNeedResetPwdLoader"
import { rootLoader } from "./loader/rootLoader"
import { teamBillingLoader } from "./loader/teamBillingLoader"
import { teamMemberLoader } from "./loader/teamMemberLoader"

export function layLoad(Comp: LazyExoticComponent<any>): ReactNode {
  return (
    <Suspense fallback={<FullPageLoading />}>
      <Comp />
    </Suspense>
  )
}

const WorkSpaceLanding = lazy(() => import("@/page/workspace"))
const InitTeamPage = lazy(() => import("@/page/workspace/InitTeamPage"))
const AppWorkSpaceLanding = lazy(() => import("@/page/workspace/apps"))
const FlowWorkSpaceLanding = lazy(() => import("@/page/workspace/flow"))
const AIAgentWorkSpaceLanding = lazy(() => import("@/page/workspace/aiAgents"))
const DriveWorkSpaceLanding = lazy(() => import("@/page/workspace/drive/file"))
const PCFilePreviewLanding = lazy(
  () => import("@/page/workspace/drive/preview"),
)
const MobilePreviewLanding = lazy(
  () => import("@/page/workspace/drive/preview/mIndex"),
)
const ResourceWorkSpaceLanding = lazy(
  () => import("@/page/workspace/resources"),
)
const AuditLogWorkSpaceLanding = lazy(() => import("@/page/workspace/auditLog"))
const SettingLanding = lazy(() => import("@/page/setting/landing"))
const PersonalSetting = lazy(() => import("@/page/setting/account/personal"))
const LanguageSetting = lazy(() => import("@/page/setting/account/language"))
const PasswordSettingPage = lazy(
  () => import("@/page/setting/account/password"),
)
const LinkedSettingPage = lazy(() => import("@/page/setting/account/linked"))
const AppSumo = lazy(() => import("@/page/setting/team/appSumo"))
const TeamBilling = lazy(() => import("@/page/setting/team/billing"))
const TeamSetting = lazy(() => import("@/page/setting/team/info"))
const TeamMembers = lazy(() => import("@/page/setting/team/member"))
const OAuth = lazy(() => import("@/page/user/oauth"))
const Unsubscribe = lazy(() => import("@/page/unsubscribe"))
const LoginPage = lazy(() => import("@/page/user/login"))
const RegisterPage = lazy(() => import("@/page/user/register"))
const ForgotPasswordPage = lazy(() => import("@/page/user/resetPassword"))
const AppSumoRedeemPage = lazy(() => import("@/page/user/appSumoRedeem"))
const PermissionPreventPage = lazy(
  () => import("@/page/workspace/permissionPreventPage"),
)

export const routerConfig: ILLARoutesObject[] = [
  {
    index: true,
    loader: rootLoader,
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<FullPageLoading />}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: "/register",
    element: (
      <Suspense fallback={<FullPageLoading />}>
        <RegisterPage />
      </Suspense>
    ),
  },
  {
    path: "/forgotPassword",
    element: (
      <Suspense fallback={<FullPageLoading />}>
        <ForgotPasswordPage />
      </Suspense>
    ),
  },
  {
    path: "/appsumo-redeem/:email",
    element: (
      <Suspense fallback={<FullPageLoading />}>
        <AppSumoRedeemPage />
      </Suspense>
    ),
    loader: checkNeedResetPwdLoader,
  },
  {
    path: "/workspace",
    element: (
      <Suspense fallback={<PCDashboardLoading />}>
        <WorkSpaceLanding />
      </Suspense>
    ),
    errorElement: <Status500 />,
    needLogin: true,
    children: [
      {
        index: true,
        element: <InitTeamPage />,
      },
      {
        path: ":teamIdentifier",
        children: [
          {
            index: true,
            element: <Navigate to="./apps" replace />,
          },
          {
            path: "apps",
            element: <AppWorkSpaceLanding />,
          },
          {
            path: "ai-agents",
            element: <AIAgentWorkSpaceLanding />,
          },
          {
            path: "drive",
            element: <PermissionPreventPage />,
            children: [
              {
                index: true,
                element: <DriveWorkSpaceLanding />,
              },
              {
                path: "preview",
                element: <PCFilePreviewLanding />,
              },
              {
                path: "preview/m",
                element: <MobilePreviewLanding />,
              },
            ],
          },
          {
            path: "flow",
            element: <PermissionPreventPage />,
            children: [
              {
                index: true,
                element: <FlowWorkSpaceLanding />,
              },
            ],
          },
          {
            path: "resources",
            element: <ResourceWorkSpaceLanding />,
          },
          {
            path: "audit-log",
            element: <AuditLogWorkSpaceLanding />,
          },
        ],
      },
    ],
  },
  {
    path: "/setting",
    element: <SettingLazyLoad Comp={lazy(() => import("@/page/setting"))} />,
    errorElement: <Status500 />,
    needLogin: true,
    children: [
      {
        index: true,
        element: <SettingLanding />,
      },
      {
        path: "account",
        element: <PersonalSetting />,
      },
      {
        path: "language",
        element: <LanguageSetting />,
      },
      {
        path: "password",
        element: <PasswordSettingPage />,
      },
      {
        path: "linked",
        element: <LinkedSettingPage />,
      },
      {
        path: ":teamIdentifier",
        children: [
          {
            path: "team-settings",
            element: <TeamSetting />,
          },
          {
            path: "members",
            element: <TeamMembers />,
            loader: teamMemberLoader,
          },
          {
            path: "branding",
            element: <Branding />,
          },
          {
            path: "billing",
            element: <TeamBilling />,
            loader: teamBillingLoader,
          },
          {
            path: "appSumo",
            element: <AppSumo />,
            loader: teamBillingLoader,
          },
        ] as ILLARoutesObject[],
      },
    ] as ILLARoutesObject[],
  },
  {
    path: "share/drive/:shareID",
    element: layLoad(lazy(() => import("@/page/share/drive"))),
  },
  {
    path: "landing/subscribed",
    element: layLoad(lazy(() => import("@/page/redirect/subscribed"))),
  },
  {
    path: "/oauth",
    element: <OAuth />,
  },
  {
    path: "/email-unsubscribe",
    needLogin: true,
    element: <Unsubscribe />,
  },
  {
    path: "/403",
    element: <Status403 />,
  },
  {
    path: "/404",
    element: <Status404 />,
  },
  {
    path: "/500",
    element: <Status500 />,
  },
  {
    path: "/redirect-expired",
    element: <RedirectExpired />,
  },
  {
    path: "/*",
    element: <Status404 />,
  },
]
