import { createIcon } from "@illa-design/react"

const AppwriteIcon = createIcon({
  title: "AppwriteIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M23.8071 17.2442V22.4922H10.4723C6.58727 22.4922 3.19512 20.3816 1.38024 17.2442C1.11641 16.7881 0.885493 16.3095 0.691791 15.8129C0.311544 14.8396 0.0725186 13.7952 0 12.7056V11.2867C0.0157442 11.0439 0.0405532 10.8029 0.072996 10.5648C0.139312 10.0763 0.239502 9.59824 0.371181 9.13354C1.61688 4.72803 5.66743 1.5 10.4723 1.5C15.2771 1.5 19.3272 4.72803 20.5729 9.13354H14.8711C13.935 7.69749 12.3148 6.74807 10.4723 6.74807C8.62971 6.74807 7.0095 7.69749 6.07346 9.13354C5.78816 9.57009 5.56676 10.0515 5.42173 10.5648C5.2929 11.02 5.2242 11.4999 5.2242 11.9961C5.2242 13.5004 5.85686 14.8563 6.87116 15.8129C7.81101 16.7008 9.07817 17.2442 10.4723 17.2442H23.8071Z"
        fill="#FD366E"
      />
      <path
        d="M23.8072 10.5649V15.813H14.0735C15.0878 14.8564 15.7204 13.5005 15.7204 11.9962C15.7204 11.5 15.6517 11.0201 15.5229 10.5649H23.8072Z"
        fill="#FD366E"
      />
    </>
  ),
})

AppwriteIcon.displayName = "AppwriteIcon"
export default AppwriteIcon
