import {
  ILLAMixpanel,
  ILLAProperties,
  ILLA_MIXPANEL_EVENT_TYPE,
  ILLA_PAGE_NAME,
} from "@illa-public/mixpanel-utils"
import { USER_ROLE } from "@illa-public/public-types"
import { getCurrentTeamInfo, getCurrentUserID } from "@illa-public/user-data"
import { ILLARoute } from "@/router"
import { store } from "@/store"

export * from "@/utils/mixpanelHelper/content"
export * from "@/utils/mixpanelHelper/reportUtils"
export * from "@/utils/mixpanelHelper/reportWithStatus"

const getTeamIdentifier = () => {
  return ILLARoute.state.matches?.[0]?.params?.teamIdentifier ?? "-1"
}

const getUserType = () => {
  const rootState = store.getState()
  const currentTeamInfo = getCurrentTeamInfo(rootState)
  if (!currentTeamInfo) return "no_team"
  const userRole = currentTeamInfo.myRole
  return USER_ROLE[userRole]
}

const mergeProperties = (
  originExtendProperty: Omit<ILLAProperties, "page"> = {},
  extendProperty?: "userRole" | "team_id" | "both",
) => {
  let mergedProperties = { ...originExtendProperty }
  switch (extendProperty) {
    case "team_id": {
      mergedProperties.team_id = getTeamIdentifier()
      break
    }
    case "userRole": {
      mergedProperties.parameter11 = getUserType()
      break
    }
    case "both": {
      mergedProperties.team_id = getTeamIdentifier()
      mergedProperties.parameter11 = getUserType()
      break
    }
    default: {
      break
    }
  }
  return mergedProperties
}

export const track = (
  event: ILLA_MIXPANEL_EVENT_TYPE,
  pageName: ILLA_PAGE_NAME,
  properties: Omit<ILLAProperties, "page"> = {},
  extendProperty?: "userRole" | "team_id" | "both",
) => {
  let mergedProperties = mergeProperties(properties, extendProperty)
  const rootState = store.getState()
  const userID = getCurrentUserID(rootState)
  ILLAMixpanel.track(event, {
    ...mergedProperties,
    page: pageName,
    user_id: userID,
  })
}

export const resourceContextHelper = (parameter1: string) => {
  return (
    event: ILLA_MIXPANEL_EVENT_TYPE,
    pageName: ILLA_PAGE_NAME,
    properties: Omit<ILLAProperties, "page">,
  ) => {
    const mergeParam = parameter1 ? { ...properties, parameter1 } : properties
    track(event, pageName, mergeParam)
  }
}
