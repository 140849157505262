import { authCloudRequest, isILLAAPiError } from "@illa-public/illa-net"
import {
  ILLAMixpanel,
  ILLA_MIXPANEL_CLOUD_PAGE_NAME,
  ILLA_MIXPANEL_EVENT_TYPE,
} from "@illa-public/mixpanel-utils"
import {
  MemberInfo,
  TeamInfo,
  TeamPersonalConfig,
} from "@illa-public/public-types"
import { teamActions } from "@illa-public/user-data"
import { v4 } from "uuid"
import { UploadResponse } from "@/api/auth"
import { upload } from "@/api/custom"
import {
  App,
  AppsResponse,
  AuditLogRequest,
  AuditLogResponse,
  UsersResponse,
} from "@/page/workspace/auditLog/interface"
import { store } from "@/store"
import { getLocalTeam, setLocalTeam } from "@/utils/auth"
import { track } from "@/utils/mixpanelHelper"

export const fetchJoinTeam = (inviteToken: string) => {
  return authCloudRequest<TeamInfo>({
    method: "PUT",
    url: `/join/${inviteToken}`,
  })
}

export const fetchMemberList = (teamID: string) => {
  return authCloudRequest<MemberInfo[]>(
    {
      url: "/members",
      method: "GET",
    },
    {
      teamID,
    },
  )
}

export const fetchCurrentUserTeamsInfo = () => {
  return authCloudRequest<TeamInfo[]>({
    url: "/teams/my",
    method: "GET",
  })
}

export const getTeamsInfo = (teamIdentifier?: string) => {
  return new Promise<TeamInfo>((resolve, reject) => {
    authCloudRequest<TeamInfo[]>({
      url: "/teams/my",
      method: "GET",
    })
      .then((response) => {
        // TIPS: can check user role
        const data = response.data ?? []
        const currentTeamInfo = data.find(
          (item) => item.identifier === teamIdentifier,
        )
        const localTeamIdentifier = getLocalTeam()
        const localTeamInfo = data.find(
          (item) => item.identifier === localTeamIdentifier,
        )
        if (currentTeamInfo) {
          store.dispatch(
            teamActions.updateTeamReducer({
              currentId: currentTeamInfo.id,
              items: data,
            }),
          )
          ILLAMixpanel.setGroup(currentTeamInfo.identifier)
          setLocalTeam(currentTeamInfo.identifier)
          resolve(currentTeamInfo)
        } else if (localTeamInfo) {
          store.dispatch(
            teamActions.updateTeamReducer({
              currentId: localTeamInfo.id,
              items: data,
            }),
          )
          ILLAMixpanel.setGroup(localTeamInfo.identifier)

          setLocalTeam(localTeamInfo.identifier)
          resolve(localTeamInfo)
        } else {
          store.dispatch(
            teamActions.updateTeamReducer({
              currentId: data?.[0]?.id,
              items: data,
            }),
          )
          data?.[0]?.identifier && ILLAMixpanel.setGroup(data?.[0]?.identifier)
          data?.[0]?.identifier && setLocalTeam(data[0].identifier)
          resolve(data?.[0] ?? {})
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const fetchDeleteTeamByID = (teamID: string) => {
  return authCloudRequest({
    method: "DELETE",
    url: "/teams/" + teamID,
  })
}

export const fetchUpdateTeamMembersConfig = async (
  config: Partial<TeamPersonalConfig>,
) => {
  return await authCloudRequest({
    url: `/teamMembers/config`,
    method: "PATCH",
    data: config,
  })
}

export const fetchRemoveTeamMembers = (
  teamID: string,
  teamMemberID: string,
) => {
  return authCloudRequest(
    {
      method: "DELETE",
      url: `/teamMembers/${teamMemberID}`,
    },
    {
      teamID,
    },
  )
}

export const fetchTeamUploadAddress = (teamID: string, type: string) => {
  const fileName = v4()
  return authCloudRequest<UploadResponse>(
    {
      url: `/icon/uploadAddress/fileName/${fileName}.${type}`,
      method: "GET",
    },
    {
      teamID,
    },
  )
}

export const fetchUploadTeamIcon = (teamID: string, file: Blob) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const type = file.type.split("/")[1]
      const getAddressResponse = await fetchTeamUploadAddress(teamID, type)
      if (getAddressResponse.data.uploadAddress) {
        const imgUrl = await upload(getAddressResponse.data.uploadAddress, file)
        resolve(imgUrl)
      }
    } catch (e) {
      reject(e)
    }
  })
}

export const getAppNameFromTeamId = async (teamId: string): Promise<App[]> => {
  try {
    const data = await authCloudRequest<AppsResponse>({
      url: `/teams/${teamId}/auditLogs/apps`,
      method: "GET",
    })
    if (!data) return []
    return data.data?.apps
  } catch (e) {
    return []
  }
}

export const getTotalUsersFromTeamId = async (teamId: string) => {
  try {
    const data = await authCloudRequest<UsersResponse>({
      url: `/teams/${teamId}/auditLogs/members`,
      method: "GET",
    })
    if (!data) return []
    return data.data?.users
  } catch (e) {
    return []
  }
}

export const getAuditLogs = async (
  teamId: string | undefined,
  data: AuditLogRequest,
) => {
  const currentTime = new Date().getTime()
  try {
    track(
      ILLA_MIXPANEL_EVENT_TYPE.REQUEST,
      ILLA_MIXPANEL_CLOUD_PAGE_NAME.AUDIT_LOGS,
      {
        parameter2: "request",
      },
      "both",
    )
    const res = await authCloudRequest<AuditLogResponse>({
      url: `/teams/${teamId}/auditLogs/listLogs`,
      method: "POST",
      data,
    })
    track(
      ILLA_MIXPANEL_EVENT_TYPE.REQUEST,
      ILLA_MIXPANEL_CLOUD_PAGE_NAME.AUDIT_LOGS,
      {
        consume: new Date().getTime() - currentTime,
        parameter2: "suc",
        parameter3: true,
      },
      "both",
    )
    if (!res) return null
    return res.data
  } catch (e) {
    if (isILLAAPiError(e)) {
      track(
        ILLA_MIXPANEL_EVENT_TYPE.REQUEST,
        ILLA_MIXPANEL_CLOUD_PAGE_NAME.AUDIT_LOGS,
        {
          consume: new Date().getTime() - currentTime,
          parameter2: "failed",
          parameter3: e.data.errorFlag,
        },
        "both",
      )
    }
    return null
  }
}

interface IFetchChangeTeamConfigRequestData {
  name?: string
  identifier?: string
  icon?: string
}

export const fetchChangeTeamConfig = (
  data: IFetchChangeTeamConfigRequestData,
  teamID: string,
) => {
  return authCloudRequest(
    { method: "PATCH", url: `/config`, data },
    {
      teamID,
    },
  )
}

interface IFetchCreateTeamRequestData {
  name: string
  identifier: string
}

export const fetchCreateTeam = (data: IFetchCreateTeamRequestData) => {
  return authCloudRequest({ method: "POST", url: "/teams", data })
}
