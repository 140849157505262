import i18n from "@/i18n"

const disallowedPatterns = [
  /.*\.?illacloud\.com/,
  /.*\.?illa\.ai/,
  /.*\.?illa\.sh/,
]

export const validateCustomDomainRule = (value: string) => {
  if (!value) return true
  const result = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(value)
  if (!result) {
    return i18n.t("team_setting.branding.custom_domain.domain_error")
  }
  for (const pattern of disallowedPatterns) {
    if (pattern.test(value)) {
      return i18n.t("team_setting.branding.custom_domain.illa_domain_error")
    }
  }
  return true
}

export const FAVICON_FILE_SIZE_LIMIT = 500 * 1024
