import { ErrorMessage } from "@hookform/error-message"
import IconHotSpot from "@illa-public/icon-hot-spot"
import { ITeamCustomInfo, SUBSCRIBE_PLAN } from "@illa-public/public-types"
import { TextLink } from "@illa-public/text-link"
import { useUpgradeModal } from "@illa-public/upgrade-modal"
import {
  getCurrentId,
  getCurrentTeamInfo,
  getPlanUtils,
  teamActions,
} from "@illa-public/user-data"
import { FC, memo, useRef, useState } from "react"
import { Controller, SubmitHandler, useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { v4 } from "uuid"
import { Button, CopyIcon, Input, getColor } from "@illa-design/react"
import { upload } from "@/api/custom"
import {
  useDeleteCustomDomainMutation,
  useGetTeamUploadFaviconURLMutation,
  useSetCustomDomainMutation,
  useUpdateTeamConfigMutation,
} from "@/redux/services/supervisorAPI"
import { ITeamConfig } from "@/redux/services/supervisorAPI/interface"
import { copy } from "@/utils/copy"
import { IDomainFormFiled } from "../../interface"
import UploadFavicon from "../UploadFavicon"
import {
  customDomainErrorMessage,
  fakeInputContainerStyle,
  fakeInputValueStyle,
  faviconFieldStyle,
  faviconHeaderStyle,
  formContentStyle,
  formLabelStyle,
  iconHotSpotStyle,
  inputAndButtonContainerStyle,
  publicFieldStyle,
  tipStyle,
} from "./style"
import {
  FAVICON_FILE_SIZE_LIMIT,
  validateCustomDomainRule,
} from "./validateRules"

const DomainForm: FC = () => {
  const teamID = useSelector(getCurrentId)

  const { t } = useTranslation()
  const [setCustomDomainMutation] = useSetCustomDomainMutation()
  const [deleteCustomDomainMutation] = useDeleteCustomDomainMutation()
  // const [validateCustomDomain, { isLoading: isValidateLoading }] =
  //   useValidateCustomDomainMutation()

  const [getTeamUploadFaviconURL] = useGetTeamUploadFaviconURLMutation()

  const [updateTeamConfig] = useUpdateTeamConfigMutation()

  const currentTeamInfo = useSelector(getCurrentTeamInfo)

  const teamPlan = getPlanUtils(currentTeamInfo)

  const upgradeModal = useUpgradeModal()
  const dispatch = useDispatch()

  // const [isValidateSuccessDomain, setIsValidateSuccessDomain] = useState(false)

  const { handleSubmit, control, formState, setError, reset, clearErrors } =
    useFormContext<IDomainFormFiled>()

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const { errors, isValid, isDirty } = formState

  const canUseCustomDomain =
    teamPlan === SUBSCRIBE_PLAN.TEAM_LICENSE_PREMIUM ||
    teamPlan === SUBSCRIBE_PLAN.TEAM_LICENSE_PLUS

  const onSubmit: SubmitHandler<IDomainFormFiled> = async (data) => {
    if (!canUseCustomDomain) {
      upgradeModal({
        modalType: "upgrade",
        from: "save_custom_domain",
      })
      return
    }
    setIsSubmitLoading(true)
    let teamConfigNeedUpdate: Partial<ITeamConfig> = {}
    const localUpdateConfig: Partial<ITeamCustomInfo> = {
      customDomain: data.customDomain,
    }
    const newFormValue: IDomainFormFiled = {
      customDomain: data.customDomain,
    }
    if (faviconBlobRef.current) {
      try {
        const nameArray = faviconBlobRef.current.name.split(".")
        const fileType = nameArray[nameArray.length - 1]

        const { uploadAddress } = await getTeamUploadFaviconURL({
          teamID: teamID!,
          fileName: v4(),
          fileType: fileType,
        }).unwrap()
        const iconURL = await upload(uploadAddress, faviconBlobRef.current!)
        teamConfigNeedUpdate.customHTMLFavicon = iconURL
        newFormValue.favicon = iconURL
        localUpdateConfig.favicon = iconURL
        faviconBlobRef.current = undefined
      } catch (e) {
        setIsSubmitLoading(false)
        return
      }
    }

    if (data.siteTitle) {
      teamConfigNeedUpdate.customHTMLTitle = data.siteTitle
      newFormValue.siteTitle = data.siteTitle
      localUpdateConfig.title = data.siteTitle
    }

    if (Object.keys(teamConfigNeedUpdate).length > 0) {
      await updateTeamConfig({
        teamID: teamID!,
        config: teamConfigNeedUpdate,
      })
    }

    if (data.customDomain) {
      await setCustomDomainMutation({
        teamID: teamID!,
        domain: data.customDomain,
      })
    } else {
      await deleteCustomDomainMutation({
        teamID: teamID!,
        domain: import.meta.env.ILLA_CNAME_HOST ?? "",
      })
    }
    dispatch(
      teamActions.updateTargetTeamInfoCustomInfoReducer({
        teamID: teamID!,
        customInfo: localUpdateConfig,
      }),
    )
    reset(newFormValue)
    setIsSubmitLoading(false)
  }

  const faviconBlobRef = useRef<File>()

  // const verifiedDomainRef = useRef<string>()

  // const handleValidateCustomDomain = useCallback(
  //   async (customDomain: string) => {
  //     const validateResult = validateCustomDomainRule(customDomain)
  //     if (typeof validateResult === "string") {
  //       setError("customDomain", {
  //         type: "manual",
  //         message: validateResult,
  //       })
  //       return
  //     }
  //     try {
  //       await validateCustomDomain({
  //         teamID: teamID!,
  //         domain: customDomain,
  //       }).unwrap()
  //       setIsValidateSuccessDomain(true)
  //       verifiedDomainRef.current = customDomain
  //     } catch {
  //       setIsValidateSuccessDomain(false)
  //       setError("customDomain", {
  //         type: "manual",
  //         message: t("team_setting.branding.custom_domain.verify_error"),
  //       })
  //     }
  //   },
  //   [setError, t, teamID, validateCustomDomain],
  // )

  // useEffect(() => {
  //   if (customDomainData?.customDomain) {
  //     handleValidateCustomDomain(customDomainData?.customDomain)
  //   }
  // }, [customDomainData?.customDomain, handleValidateCustomDomain])

  // const handleVerify = async () => {
  //   if (!canUseCustomDomain) {
  //     upgradeModal({
  //       modalType: "upgrade",
  //       from: "verify_custom_domain",
  //     })
  //     return
  //   }
  //   const customDomain = getValues().customDomain
  //   handleValidateCustomDomain(customDomain)
  // }

  const handleUpdateFavicon = async (
    event: React.ChangeEvent<HTMLInputElement>,
    callBack: (value: string) => void,
  ) => {
    const files = event.target.files
    if (!files) return
    const file = files[0]

    if (file.size > FAVICON_FILE_SIZE_LIMIT) {
      faviconBlobRef.current = undefined
      clearErrors("favicon")
      callBack("")
      setError("favicon", {
        type: "manual",
        message: t("team_setting.branding.custom_domain_error.size"),
      })
      event.target.value = ""
      return
    }

    const reader = new FileReader()
    reader.onload = function (e) {
      if (!e.target?.result) return
      const img = new Image()
      const base64 = e.target?.result as string
      img.src = base64
      img.onload = function () {
        if (
          (img.width !== 32 || img.height !== 32) &&
          (img.width !== 57 || img.height !== 57) &&
          (img.width !== 72 || img.height !== 72) &&
          (img.width !== 114 || img.height !== 114) &&
          (img.width !== 144 || img.height !== 144)
        ) {
          faviconBlobRef.current = undefined
          clearErrors("favicon")
          callBack("")
          setError("favicon", {
            type: "manual",
            message: t("team_setting.branding.custom_domain_error.pixel"),
          })
          event.target.value = ""
        } else {
          clearErrors("favicon")
          faviconBlobRef.current = file
          callBack(base64)
          event.target.value = ""
        }
      }
    }

    reader.readAsDataURL(file)
  }

  const handleCopyCname = () => {
    copy(import.meta.env.ILLA_CNAME_HOST)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={formContentStyle}>
      <div css={faviconFieldStyle}>
        <div css={faviconHeaderStyle}>
          <label css={formLabelStyle}>
            {t("team_setting.branding.custom_domain.favicon")}
          </label>
          <p css={tipStyle}>
            {t("team_setting.branding.custom_domain.favicon_tips")}
          </p>
        </div>
        <Controller
          name="favicon"
          render={({ field }) => (
            <UploadFavicon
              value={field.value}
              onChange={(e) => {
                handleUpdateFavicon(e, field.onChange)
              }}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="favicon"
          render={({ message }) => (
            <p css={customDomainErrorMessage}>{message}</p>
          )}
        />
      </div>
      <div css={publicFieldStyle}>
        <label css={formLabelStyle}>
          {t("team_setting.branding.custom_domain.title")}
        </label>
        <Controller
          name="siteTitle"
          control={control}
          render={({ field }) => (
            <Input
              colorScheme="techPurple"
              {...field}
              size="large"
              showWordLimit
              maxLength={60}
            />
          )}
        />
      </div>
      <div css={publicFieldStyle}>
        <label css={formLabelStyle}>
          {t("team_setting.branding.custom_domain.custom_domain_label")}
        </label>
        <div css={inputAndButtonContainerStyle}>
          <Controller
            name="customDomain"
            control={control}
            rules={{
              validate: validateCustomDomainRule,
            }}
            render={({ field, fieldState }) => (
              <Input
                colorScheme="techPurple"
                // bdRadius="8px 0px 0px 8px"
                size="large"
                placeholder={t(
                  "team_setting.branding.custom_domain.domain_placeholder",
                )}
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
          {/* <Button
            colorScheme="grayBlue"
            bdRadius="0px 8px 8px 0px"
            onClick={handleVerify}
            loading={isValidateLoading}
            disabled={
              !getValues("customDomain") ||
              !!getFieldState("customDomain").error
            }
          >
            {isValidateLoading ? (
              ""
            ) : isValidateSuccessDomain &&
              verifiedDomainRef.current === getValues("customDomain") ? (
              <SuccessIcon c={getColor("green", "03")} />
            ) : (
              t("team_setting.branding.custom_domain.verify")
            )}
          </Button> */}
        </div>
        <ErrorMessage
          errors={errors}
          name="customDomain"
          render={({ message }) => (
            <p css={customDomainErrorMessage}>{message}</p>
          )}
        />
      </div>
      <div css={publicFieldStyle}>
        <p css={tipStyle}>
          <Trans
            i18nKey="team_setting.branding.custom_domain.domain_tips"
            t={t}
            components={[
              <TextLink
                key="doc"
                onClick={() => {
                  window.open(
                    "https://docs.illacloud.com/custom-domain",
                    "_blank",
                  )
                }}
              />,
            ]}
          />
        </p>
        <div css={fakeInputContainerStyle}>
          <p css={fakeInputValueStyle}>{import.meta.env.ILLA_CNAME_HOST}</p>
          <IconHotSpot
            css={iconHotSpotStyle}
            onClick={handleCopyCname}
            type="button"
            inactiveColor={getColor("grayBlue", "02")}
          >
            <CopyIcon />
          </IconHotSpot>
        </div>
      </div>

      <Button
        colorScheme="black"
        type="submit"
        size="large"
        loading={isSubmitLoading}
        disabled={!isValid || !isDirty}
      >
        {t("team_setting.branding.custom_domain.save")}
      </Button>
    </form>
  )
}

export default memo(DomainForm)
