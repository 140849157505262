import { css } from "@emotion/react"
import { applyMobileStyle } from "@illa-public/utils"
import { getColor } from "@illa-design/react"

export const formContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${applyMobileStyle(css`
    gap: 32px;
  `)}
`

export const publicFieldStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${applyMobileStyle(css`
    gap: 12px;
  `)}
`

export const faviconFieldStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const faviconHeaderStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const formLabelStyle = css`
  color: ${getColor("grayBlue", "02")};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  ${applyMobileStyle(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`

export const tipStyle = css`
  color: ${getColor("grayBlue", "03")};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
`

export const inputAndButtonContainerStyle = css`
  display: flex;
`

export const iconHotSpotStyle = css`
  width: 22px;
  height: 22px;
  ${applyMobileStyle(css`
    width: 32px;
    height: 32px;
  `)}
`

export const customDomainErrorMessage = css`
  color: ${getColor("red", "03")};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
`

export const fakeInputContainerStyle = css`
  display: flex;
  border-radius: 8px;
  padding: 9px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${getColor("grayBlue", "09")};
`

export const fakeInputValueStyle = css`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${getColor("grayBlue", "02")};
`
