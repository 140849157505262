import { FC, memo, useRef } from "react"
import { UploadIcon } from "@illa-design/react"
import { IUploadFaviconProps } from "./interface"
import {
  faviconStyle,
  inputStyle,
  uploadFavicon,
  uploadIconContainerStyle,
} from "./style"

const UploadFavicon: FC<IUploadFaviconProps> = (props) => {
  const { onChange, value } = props
  const uploadInputRef = useRef<HTMLInputElement>(null)

  const handleClickUploadButton = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click()
    }
  }

  return (
    <div css={uploadFavicon}>
      <input
        name="favicon"
        type="file"
        accept="image/*"
        css={inputStyle}
        ref={uploadInputRef}
        onChange={onChange}
      />
      <div css={uploadIconContainerStyle} onClick={handleClickUploadButton}>
        {value ? <img src={value} css={faviconStyle} /> : <UploadIcon />}
      </div>
    </div>
  )
}

export default memo(UploadFavicon)
