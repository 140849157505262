import { createIcon } from "@illa-design/react"

const UpstashIcon = createIcon({
  title: "UpstashIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M4.19971 19.8132C7.64763 23.261 13.2378 23.261 16.6857 19.8132C20.1336 16.3652 20.1336 10.7751 16.6857 7.32715L15.125 8.88788C17.7109 11.4738 17.7109 15.6665 15.125 18.2524C12.539 20.8383 8.3464 20.8383 5.76044 18.2524L4.19971 19.8132Z"
        fill="#00E9A3"
      />
      <path
        d="M7.32129 16.6917C9.04524 18.4157 11.8403 18.4157 13.5643 16.6917C15.2882 14.9678 15.2882 12.1727 13.5643 10.4487L12.0035 12.0095C12.8655 12.8714 12.8655 14.269 12.0035 15.131C11.1415 15.993 9.744 15.993 8.88203 15.131L7.32129 16.6917Z"
        fill="#00E9A3"
      />
      <path
        d="M19.8063 4.20458C16.3584 0.756678 10.7682 0.756678 7.32032 4.20458C3.87239 7.6525 3.87239 13.2427 7.32032 16.6906L8.88103 15.1299C6.29514 12.5439 6.29514 8.35126 8.88103 5.76536C11.467 3.1794 15.6596 3.1794 18.2455 5.76536L19.8063 4.20458Z"
        fill="#00E9A3"
      />
      <path
        d="M16.6854 7.32665C14.9615 5.60271 12.1663 5.60271 10.4424 7.32665C8.71842 9.05063 8.71842 11.8457 10.4424 13.5696L12.0031 12.0089C11.1412 11.1469 11.1412 9.74939 12.0031 8.88738C12.8651 8.02541 14.2626 8.02541 15.1247 8.88738L16.6854 7.32665Z"
        fill="#00E9A3"
      />
      <path
        d="M19.8063 4.20458C16.3584 0.756678 10.7682 0.756678 7.32032 4.20458C3.87239 7.6525 3.87239 13.2427 7.32032 16.6906L8.88103 15.1299C6.29514 12.5439 6.29514 8.35126 8.88103 5.76536C11.467 3.1794 15.6596 3.1794 18.2455 5.76536L19.8063 4.20458Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M16.6854 7.32665C14.9615 5.60271 12.1663 5.60271 10.4424 7.32665C8.71842 9.05063 8.71842 11.8457 10.4424 13.5696L12.0031 12.0089C11.1412 11.1469 11.1412 9.74939 12.0031 8.88738C12.8651 8.02541 14.2626 8.02541 15.1247 8.88738L16.6854 7.32665Z"
        fill="white"
        fillOpacity="0.5"
      />
    </>
  ),
})

UpstashIcon.displayName = "UpstashIcon"
export default UpstashIcon
