import { createIcon } from "@illa-design/react"

const DynamoIcon = createIcon({
  title: "DynamoIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M15.4823 23.265H15.7472L20.0723 21.1048L20.1443 21.0031V3.76053L20.0723 3.65838L15.7472 1.5H15.479L15.4823 23.265Z"
        fill="#5294CF"
      />
      <path
        d="M9.85394 23.265H9.58527L5.26389 21.1048L5.1762 20.9541L5.13171 3.84835L5.26389 3.65838L9.58527 1.5H9.85727L9.85394 23.265Z"
        fill="#2D72B8"
      />
      <path d="M9.5929 1.5H15.7433V23.265H9.5929V1.5Z" fill="#2D72B8" />
      <path
        d="M22.3363 11.8928L22.1902 11.8605L20.1033 11.6661L20.0724 11.6815L15.7435 11.5086H9.59286L5.26386 11.6815V8.38443L5.25933 8.38684L5.26386 8.37719L9.59286 7.37533H15.7435L20.0724 8.37719L21.6638 9.22205V8.67888L22.3363 8.6054L22.2667 8.46976L20.1374 6.94892L20.0724 6.96997L15.7435 5.6302H9.59286L5.26386 6.96997V3.65771L3 6.3067V8.62018L3.01752 8.60752L3.67254 8.67888V9.23323L3 9.59271V11.8946L3.01752 11.8928L3.67254 11.9025V12.8687L3.1074 12.8771L3 12.8669V15.1698L3.67254 15.531V16.0917L3.02817 16.1628L3 16.1416V18.4548L5.26386 21.1041V17.7921L9.59286 19.1319H15.7435L20.0851 17.7882L20.1429 17.8135L22.2443 16.315L22.3363 16.1658L21.6638 16.0917V15.5422L21.5757 15.507L20.1374 16.2814L20.0851 16.39L15.7435 17.3862L9.59286 17.3874L5.26386 16.3858V13.0767L9.59286 13.249V13.2532H15.7435L20.0724 13.0767L20.1709 13.1219L22.1937 12.948L22.3363 12.8783L21.6638 12.8687V11.9025L22.3363 11.8928Z"
        fill="#1A476F"
      />
      <path
        d="M20.0724 17.7921V21.1041L22.3363 18.4548V16.1568L20.0854 17.7882L20.0724 17.7921ZM20.0724 16.393L20.0854 16.39L22.3363 15.1809V12.8687L20.0724 13.0767V16.393ZM20.0854 8.38021L20.0724 8.37719V11.6815L22.3363 11.8928V9.58177L20.0854 8.38021ZM20.0854 6.98295L22.3363 8.6054V6.3073L20.0724 3.65771V6.96997L20.0854 6.9745V6.98295Z"
        fill="#5294CF"
      />
    </>
  ),
})

DynamoIcon.displayName = "DynamoIcon"
export default DynamoIcon
