import { createSlice } from "@reduxjs/toolkit"
import { updateBillingInfoReducer } from "@/redux/billing/billingReducer"
import { billingInitialState } from "@/redux/billing/billingState"

const billingSlice = createSlice({
  name: "billing",
  initialState: billingInitialState,
  reducers: {
    updateBillingInfoReducer,
  },
})

export const billingActions = billingSlice.actions
export default billingSlice.reducer
