import { createIcon } from "@illa-design/react"

const ElasticIcon = createIcon({
  title: "ElasticIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <g clipPath="url(#zianj8k1ea)">
        <path
          d="M16.74 8.4h-6.27c.03.15.09.3.12.42.21.72.33 1.47.39 2.25.03.3.03.63.03.93 0 .3 0 .63-.03.93-.06.78-.18 1.53-.39 2.25-.03.15-.09.3-.12.42h6.27c1.98 0 3.57-1.62 3.57-3.6s-1.59-3.6-3.57-3.6z"
          fill="#00A9E5"
        />
        <path
          d="M10.98 12.93c.03-.3.03-.63.03-.93 0-.3 0-.63-.03-.93-.06-.78-.18-1.53-.39-2.25-.03-.15-.09-.3-.12-.42H.78C.42 9.54.24 10.74.24 12c0 1.26.18 2.46.54 3.6h9.69c.03-.15.09-.3.12-.42.21-.72.33-1.47.39-2.25z"
          fill="#353535"
        />
        <path
          d="M17.82 16.8h-7.8c-.24.54-.51 1.05-.81 1.53-.45.75-.99 1.41-1.59 2.04-.21.21-.42.39-.63.6-.42.39-.9.72-1.38 1.05C7.5 23.28 9.78 24 12.21 24c4.17 0 7.83-2.13 9.99-5.34a6.118 6.118 0 0 0-4.38-1.86z"
          fill="#00BFB3"
        />
        <path
          d="M9.21 18.33c.3-.48.57-.99.81-1.53H1.23c.93 2.13 2.46 3.96 4.41 5.22.48-.33.93-.66 1.38-1.05.21-.18.42-.39.63-.6.57-.6 1.11-1.29 1.56-2.04z"
          fill="#019B8F"
        />
        <path
          d="M7.62 3.63c-.21-.21-.42-.39-.63-.6-.42-.39-.9-.72-1.38-1.05C3.69 3.24 2.16 5.07 1.2 7.2h8.79c-.24-.54-.51-1.05-.81-1.53-.42-.75-.96-1.44-1.56-2.04z"
          fill="#F9B110"
        />
        <path
          d="M12.21 0C9.78 0 7.5.72 5.61 1.98c.48.33.93.66 1.38 1.05.21.18.42.39.63.6.6.63 1.14 1.29 1.59 2.04.3.48.57.99.81 1.53h7.8c1.71 0 3.27-.72 4.38-1.86C20.04 2.13 16.38 0 12.21 0z"
          fill="#FED10A"
        />
      </g>
      <defs>
        <clipPath id="zianj8k1ea">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
})

ElasticIcon.displayName = "ElasticIcon"
export default ElasticIcon
