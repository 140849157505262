import { css } from "@emotion/react"
import { getColor } from "@illa-design/react"

export const pageContainerStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const headerContainerStyle = css`
  width: 100%;
  height: 48px;
  padding: 16px;
  display: flex;
  align-items: center;
`

export const contentContainerStyle = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const statusIconStyle = css`
  width: 96px;
  height: 96px;
  border-radius: 50px;
  background-color: ${getColor("grayBlue", "09")};
`

export const contentCardStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 400px;
`

export const titleAndDescContentStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const titleStyle = css`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${getColor("grayBlue", "02")};
`

export const descStyle = css`
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 22px;
  color: ${getColor("grayBlue", "03")};
  text-align: center;
`
