import { createIcon } from "@illa-design/react"

const NeonIcon = createIcon({
  title: "NeonIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <g clipPath="url(#clip0_11643_1332)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.138C0 3.04053 0.435967 1.98802 1.21199 1.21199C1.98802 0.435967 3.04053 0 4.138 0L19.862 0C20.9595 0 22.012 0.435967 22.788 1.21199C23.564 1.98802 24 3.04053 24 4.138V17.5113C24 19.8753 21.008 20.9013 19.5573 19.0353L15.0207 13.1993V20.276C15.0207 21.2637 14.6283 22.2109 13.9299 22.9093C13.2315 23.6077 12.2843 24 11.2967 24H4.138C3.04053 24 1.98802 23.564 1.21199 22.788C0.435967 22.012 0 20.9595 0 19.862L0 4.138ZM4.138 3.31067C3.68067 3.31067 3.31067 3.68067 3.31067 4.13733V19.862C3.31067 20.3193 3.68067 20.69 4.13733 20.69H11.4207C11.6493 20.69 11.71 20.5047 11.71 20.276V10.7867C11.71 8.422 14.702 7.396 16.1533 9.26267L20.69 15.098V4.138C20.69 3.68067 20.7327 3.31067 20.276 3.31067H4.138Z"
          fill="#00E0D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.138C0 3.04053 0.435967 1.98802 1.21199 1.21199C1.98802 0.435967 3.04053 0 4.138 0L19.862 0C20.9595 0 22.012 0.435967 22.788 1.21199C23.564 1.98802 24 3.04053 24 4.138V17.5113C24 19.8753 21.008 20.9013 19.5573 19.0353L15.0207 13.1993V20.276C15.0207 21.2637 14.6283 22.2109 13.9299 22.9093C13.2315 23.6077 12.2843 24 11.2967 24H4.138C3.04053 24 1.98802 23.564 1.21199 22.788C0.435967 22.012 0 20.9595 0 19.862L0 4.138ZM4.138 3.31067C3.68067 3.31067 3.31067 3.68067 3.31067 4.13733V19.862C3.31067 20.3193 3.68067 20.69 4.13733 20.69H11.4207C11.6493 20.69 11.71 20.5047 11.71 20.276V10.7867C11.71 8.422 14.702 7.396 16.1533 9.26267L20.69 15.098V4.138C20.69 3.68067 20.7327 3.31067 20.276 3.31067H4.138Z"
          fill="url(#paint0_linear_11643_1332)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.138C0 3.04053 0.435967 1.98802 1.21199 1.21199C1.98802 0.435967 3.04053 0 4.138 0L19.862 0C20.9595 0 22.012 0.435967 22.788 1.21199C23.564 1.98802 24 3.04053 24 4.138V17.5113C24 19.8753 21.008 20.9013 19.5573 19.0353L15.0207 13.1993V20.276C15.0207 21.2637 14.6283 22.2109 13.9299 22.9093C13.2315 23.6077 12.2843 24 11.2967 24H4.138C3.04053 24 1.98802 23.564 1.21199 22.788C0.435967 22.012 0 20.9595 0 19.862L0 4.138ZM4.138 3.31067C3.68067 3.31067 3.31067 3.68067 3.31067 4.13733V19.862C3.31067 20.3193 3.68067 20.69 4.13733 20.69H11.4207C11.6493 20.69 11.71 20.5047 11.71 20.276V10.7867C11.71 8.422 14.702 7.396 16.1533 9.26267L20.69 15.098V4.138C20.69 3.68067 20.7327 3.31067 20.276 3.31067H4.138Z"
          fill="url(#paint1_linear_11643_1332)"
          fillOpacity="0.4"
        />
        <path
          d="M19.8617 0C20.9592 0 22.0117 0.435967 22.7877 1.21199C23.5638 1.98802 23.9997 3.04053 23.9997 4.138V17.5113C23.9997 19.8753 21.0077 20.9013 19.5571 19.0353L15.0204 13.1993V20.276C15.0204 21.2637 14.628 22.2109 13.9297 22.9093C13.2313 23.6077 12.2841 24 11.2964 24C11.3507 24 11.4044 23.9893 11.4546 23.9685C11.5047 23.9478 11.5503 23.9173 11.5887 23.8789C11.627 23.8406 11.6575 23.795 11.6783 23.7448C11.699 23.6947 11.7097 23.6409 11.7097 23.5867V10.7867C11.7097 8.422 14.7017 7.396 16.1531 9.26267L20.6897 15.098V0.827333C20.6897 0.370667 20.3191 0 19.8617 0Z"
          fill="#63F655"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11643_1332"
          x1="24"
          y1="24"
          x2="2.89667"
          y2="-9.22469e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#62F755" />
          <stop offset="1" stopColor="#8FF986" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11643_1332"
          x1="24"
          y1="24"
          x2="9.74467"
          y2="18.4553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.9" />
          <stop offset="1" stopColor="#1A1A1A" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_11643_1332">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})

NeonIcon.displayName = "NeonIcon"
export default NeonIcon
