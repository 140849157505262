import { CONTAINER_TYPE, ComponentTreeNode } from "@illa-public/public-types"
import { v4 } from "uuid"
import { DATA_CONTAINER_NODE, FORM_CONTAINER_NODE } from "./containerNode"
import {
  HEADER_ADD_NEW_BUTTON,
  HEADER_INPUT_NODE,
  HEADER_TITLE_TEXT_NODE,
} from "./widgetNode"

export const BODY_SECTION_CONTAINER: ComponentTreeNode = {
  version: 0,
  displayName: "bodySection1-bodySectionContainer1",
  parentNode: "bodySection1",
  showName: "bodySection1-bodySectionContainer1",
  childrenNode: [
    HEADER_TITLE_TEXT_NODE,
    HEADER_INPUT_NODE,
    HEADER_ADD_NEW_BUTTON,
    FORM_CONTAINER_NODE,
    DATA_CONTAINER_NODE,
  ],
  type: "CONTAINER_NODE",
  containerType: CONTAINER_TYPE.EDITOR_DOT_PANEL,
  h: 0,
  w: 0,
  minH: 0,
  minW: 0,
  x: -1,
  y: -1,
  z: 0,
  props: {},
}

export const BODY_SECTION_NODE: ComponentTreeNode = {
  version: 0,
  displayName: "bodySection1",
  parentNode: "page1",
  showName: "bodySection",
  childrenNode: [BODY_SECTION_CONTAINER],
  type: "SECTION_NODE",
  containerType: CONTAINER_TYPE.EDITOR_LAYOUT_SQUARE,
  h: 0,
  w: 0,
  minH: 0,
  minW: 0,
  x: -1,
  y: -1,
  z: 0,
  props: {
    currentViewIndex: 0,
    defaultViewKey: "sub-page1",
    sectionViewConfigs: [
      {
        id: v4(),
        key: "sub-page1",
        path: "sub-page1",
        viewDisplayName: "bodySection1-bodySectionContainer1",
      },
    ],
    style: { padding: { mode: "all", size: "24" } },
    viewSortedKey: ["bodySection1-bodySectionContainer1"],
  },
}

export const PAGE_NODE: ComponentTreeNode = {
  version: 0,
  displayName: "page1",
  parentNode: "root",
  showName: "page",
  childrenNode: [
    BODY_SECTION_NODE,
    {
      version: 0,
      displayName: "modalSection1",
      parentNode: "page1",
      showName: "modalSection",
      childrenNode: [],
      type: "MODAL_SECTION_NODE",
      containerType: CONTAINER_TYPE.EDITOR_LAYOUT_SQUARE,
      h: 0,
      w: 0,
      minH: 0,
      minW: 0,
      x: -1,
      y: -1,
      z: 0,
      props: {},
    },
  ],
  type: "PAGE_NODE",
  containerType: CONTAINER_TYPE.EDITOR_PAGE_SQUARE,
  h: 0,
  w: 0,
  minH: 0,
  minW: 0,
  x: -1,
  y: -1,
  z: 0,
  props: {
    bodyColumns: 32,
    bottomHeight: 0,
    canvasSize: "fixed",
    canvasWidth: 1440,
    footerColumns: 32,
    hasFooter: false,
    hasHeader: false,
    hasLeft: false,
    hasRight: false,
    headerColumns: 32,
    isFooterFixed: true,
    isHeaderFixed: true,
    isLeftFixed: true,
    isRightFixed: true,
    layout: "default",
    leftColumns: 8,
    leftPosition: "NONE",
    leftWidth: 0,
    rightColumns: 8,
    rightPosition: "NONE",
    rightWidth: 0,
    showLeftFoldIcon: false,
    showRightFoldIcon: false,
    topHeight: 0,
  },
}

export const ROOT_NODE: ComponentTreeNode = {
  version: 0,
  displayName: "root",
  parentNode: "",
  showName: "root",
  childrenNode: [PAGE_NODE],
  type: "DOT_PANEL",
  containerType: CONTAINER_TYPE.EDITOR_DOT_PANEL,
  h: 0,
  w: 0,
  minH: 0,
  minW: 0,
  x: -1,
  y: -1,
  z: 0,
  props: { currentPageIndex: 0, pageSortedKey: ["page1"] },
}
