import { ILLAMixpanel } from "@illa-public/mixpanel-utils"
import { USER_ROLE } from "@illa-public/public-types"
import {
  CurrentUserInitialState,
  currentUserActions,
  teamActions,
} from "@illa-public/user-data"
import { getAuthToken, getILLACloudURL } from "@illa-public/utils"
import { fetchLogout } from "@/services/auth"
import { store } from "@/store"
import { ILLACloudStorage } from "@/utils/storage"

const CURRENT_TEAM_ID_KEY = "currentTeamID"

export const setLocalTeam = (teamIdentifier: string) => {
  return ILLACloudStorage.setLocalStorage("teamIdentifier", teamIdentifier)
}

export const setLocalCurrentTeamID = (teamID: string) => {
  ILLACloudStorage.setLocalStorage(CURRENT_TEAM_ID_KEY, teamID)
}

export const getLocalCurrentTeamID = () => {
  return ILLACloudStorage.getLocalStorage(CURRENT_TEAM_ID_KEY)
}

export const getLocalTeam = () => {
  return ILLACloudStorage.getLocalStorage("teamIdentifier")
}

export const removeLocalTeam = () => {
  return ILLACloudStorage.removeLocalStorage("teamIdentifier")
}

export const checkAuthRole = (
  currentRole: USER_ROLE,
  allowedRoles: USER_ROLE[],
) => {
  return allowedRoles.includes(currentRole)
}

export const onClickLogout = async () => {
  ILLAMixpanel.reset()
  const ILLAToken = getAuthToken()
  ILLACloudStorage.clearLocalStorage()
  store.dispatch(
    currentUserActions.updateCurrentUserReducer(CurrentUserInitialState),
  )
  store.dispatch(teamActions.updateTeamReducer({}))
  if (!ILLAToken) {
    window.location.href = `${getILLACloudURL(window.customDomain)}/login`
    return
  }
  try {
    await fetchLogout(ILLAToken)
  } catch (e) {
  } finally {
    window.location.href = `${getILLACloudURL(window.customDomain)}/login`
  }
}
