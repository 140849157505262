import { LayoutAutoChange } from "@illa-public/layout-auto-change"
import { getCurrentTeamInfo } from "@illa-public/user-data"
import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import SettingMobileLayout from "@/page/setting/layout/mobile"
import { IDomainFormFiled } from "./interface"
import MobileCustomDomain from "./mobile"
import PCCustomDomain from "./pc"

const Branding: FC = () => {
  const currentTeamInfo = useSelector(getCurrentTeamInfo)!
  const customConfig = currentTeamInfo.customInfo
  const methods = useForm<IDomainFormFiled>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      customDomain: customConfig.customDomain,
      favicon: customConfig.favicon,
      siteTitle: customConfig.title,
    },
  })

  useEffect(() => {
    if (customConfig) {
      methods.reset({
        customDomain: customConfig.customDomain,
        favicon: customConfig.favicon,
        siteTitle: customConfig.title,
      })
    }
  }, [customConfig, methods])

  return (
    <FormProvider {...methods}>
      <LayoutAutoChange
        desktopPage={<PCCustomDomain />}
        mobilePage={
          <SettingMobileLayout>
            <MobileCustomDomain />
          </SettingMobileLayout>
        }
      />
    </FormProvider>
  )
}

export default Branding
