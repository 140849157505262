import { currentUserReducer, teamReducer } from "@illa-public/user-data"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import billingReducer from "@/redux/billing/billingSlice"
import { supervisorAPI } from "./redux/services/supervisorAPI"

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    team: teamReducer,
    billing: billingReducer,
    [supervisorAPI.reducerPath]: supervisorAPI.reducer,
  },
  devTools: import.meta.env.MODE === "development",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(supervisorAPI.middleware)
  },
})

setupListeners(store.dispatch)

export type ILLARootState = ReturnType<typeof store.getState>
