import { createIcon } from "@illa-design/react"

const AirtableIcon = createIcon({
  title: "AirtableIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M10.7116 2.1751L1.76802 5.87584C1.27066 6.08167 1.27581 6.78811 1.77629 6.98649L10.7573 10.548C11.5465 10.8609 12.4252 10.8609 13.2143 10.548L22.1954 6.98649C22.6957 6.78811 22.701 6.08167 22.2035 5.87584L13.2601 2.1751C12.4441 1.83747 11.5275 1.83747 10.7116 2.1751Z"
        fill="#FFBF00"
      />
      <path
        d="M12.7827 12.493V21.3901C12.7827 21.8133 13.2094 22.1031 13.6028 21.9471L23.6104 18.0627C23.8388 17.9721 23.9887 17.7513 23.9887 17.5057V8.60857C23.9887 8.18538 23.562 7.89562 23.1686 8.05155L13.161 11.936C12.9327 12.0266 12.7827 12.2473 12.7827 12.493Z"
        fill="#26B5F8"
      />
      <path
        d="M10.4458 12.952L7.4758 14.386L7.17424 14.5318L0.904678 17.5358C0.507254 17.7276 0 17.438 0 16.9965V8.64582C0 8.48609 0.0818983 8.34819 0.191729 8.24432C0.237559 8.19836 0.289492 8.16053 0.343458 8.13056C0.493288 8.04066 0.706983 8.01666 0.888678 8.08853L10.3959 11.8554C10.8792 12.0472 10.9172 12.7243 10.4458 12.952Z"
        fill="#ED3049"
      />
      <path
        d="M10.446 12.952L7.47596 14.386L0.191895 8.24432C0.237725 8.19836 0.289657 8.16053 0.343623 8.13056C0.493454 8.04066 0.707149 8.01666 0.888844 8.08853L10.3961 11.8554C10.8794 12.0472 10.9173 12.7243 10.446 12.952Z"
        fill="black"
        fillOpacity="0.25"
      />
    </>
  ),
})

AirtableIcon.displayName = "AirtableIcon"
export default AirtableIcon
