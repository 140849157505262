import { SUBSCRIBE_PLAN } from "@illa-public/public-types"
import { UpgradeTag } from "@illa-public/upgrade-tag"
import { getCurrentTeamInfo, getPlanUtils } from "@illa-public/user-data"
import { FC, memo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { headerContainerStyle, titleContainerStyle, titleStyle } from "./style"

const BrandingPageHeader: FC = () => {
  const { t } = useTranslation()

  const currentTeamInfo = useSelector(getCurrentTeamInfo)

  const teamPlan = getPlanUtils(currentTeamInfo)

  return (
    <div css={headerContainerStyle}>
      <div css={titleContainerStyle}>
        <h1 css={titleStyle}> {t("team_setting.branding.title.branding")}</h1>
        {teamPlan !== SUBSCRIBE_PLAN.TEAM_LICENSE_PREMIUM &&
          teamPlan !== SUBSCRIBE_PLAN.TEAM_LICENSE_PLUS && <UpgradeTag />}
      </div>
    </div>
  )
}

export default memo(BrandingPageHeader)
