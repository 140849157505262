import { css } from "@emotion/react"
import { getColor } from "@illa-design/react"

export const customDomainContainerStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const titleStyle = css`
  color: ${getColor("grayBlue", "02")};
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
`
