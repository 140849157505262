import { getILLACloudURL } from "@illa-public/utils"
import { FC, memo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { Button, Result500Icon } from "@illa-design/react"
import ILLALogoPurple from "@/assets/public/illa-logo-purple.svg?react"
import {
  contentCardStyle,
  contentContainerStyle,
  descStyle,
  headerContainerStyle,
  pageContainerStyle,
  statusIconStyle,
  titleAndDescContentStyle,
  titleStyle,
} from "./style"

const RedirectExpired: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const redirectURL = searchParams.get("redirectURL")

  const handleClickButton = () => {
    window.location.href = redirectURL ?? getILLACloudURL()
  }

  return (
    <div css={pageContainerStyle}>
      <header css={headerContainerStyle}>
        <ILLALogoPurple />
      </header>
      <div css={contentContainerStyle}>
        <div css={contentCardStyle}>
          <Result500Icon css={statusIconStyle} />
          <div css={titleAndDescContentStyle}>
            <h1 css={titleStyle}>
              {t("team_setting.branding.custom_domain_expired.title")}
            </h1>
            <p css={descStyle}>
              {t("team_setting.branding.custom_domain_expired.desc")}
            </p>
          </div>
          <Button colorScheme="techPurple" onClick={handleClickButton}>
            {t("team_setting.branding.custom_domain_expired.button")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(RedirectExpired)
