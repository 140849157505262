import { createIcon } from "@illa-design/react"

const RestApiIcon = createIcon({
  title: "RestApiIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M9.13125 21V18.75H6.68437C5.325 18.75 5.05312 18.8182 5.05312 17.1521V14.4347C5.05312 13.0661 4.10625 12.1735 2.33437 12.0942V11.9157C4.10625 11.8364 5.05312 10.9339 5.05312 9.5752V6.84793C5.05312 5.18183 5.325 5.25 6.68437 5.25H9.13125V3H6.21562C3.675 3 2.85937 3.8529 2.85937 6.49091V8.69256C2.85937 10.2496 2.11875 10.9735 0 10.8248V13.1851C2.11875 13.0364 2.85937 13.7603 2.85937 15.3075V17.5091C2.85937 20.1471 3.675 21 6.21562 21H9.13125Z"
        fill="#C9CDD4"
      />
      <path
        d="M17.7845 21C20.325 21 21.1407 20.1471 21.1407 17.5091V15.3075C21.1407 13.7603 21.8812 13.0364 24 13.1851V10.8248C21.8812 10.9735 21.1407 10.2496 21.1407 8.69256V6.49091C21.1407 3.8529 20.325 3 17.7845 3H15V5.25H15.9469C17.3062 5.25 18.9469 5.18183 18.9469 6.84793V9.5752C18.9469 10.9339 19.8937 11.8364 21.6657 11.9157V12.0942C19.8937 12.1735 18.9469 13.0661 18.9469 14.4347V17.1521C18.9469 18.8182 17.3062 18.75 15.9469 18.75H15V21H17.7845Z"
        fill="#C9CDD4"
      />
      <path
        d="M7.5 13.5C8.32843 13.5 9 12.8284 9 12C9 11.1716 8.32843 10.5 7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5Z"
        fill="#E5E6EB"
      />
      <path
        d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
        fill="#E5E6EB"
      />
      <path
        d="M16.5 13.5C17.3284 13.5 18 12.8284 18 12C18 11.1716 17.3284 10.5 16.5 10.5C15.6716 10.5 15 11.1716 15 12C15 12.8284 15.6716 13.5 16.5 13.5Z"
        fill="#E5E6EB"
      />
    </>
  ),
})

RestApiIcon.displayName = "RestApiIcon"
export default RestApiIcon
