import { createIcon } from "@illa-design/react"

const AIAgentIcon = createIcon({
  title: "AIAgentIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0542 3L14.8301 3.47424L13.4977 8.43013L17.4306 4.51053L18.7307 5.80616L15.0947 9.42981L20.5428 7.97496L21.0187 9.74481L16.0516 11.0712L21.5 12.5262L21.0242 14.296L16.0922 12.979C16.1536 12.7135 16.186 12.4369 16.186 12.1528C16.186 10.1289 14.5397 8.48823 12.5089 8.48823C10.4781 8.48823 8.83181 10.1289 8.83181 12.1528C8.83181 12.4388 8.86468 12.7171 8.92681 12.9841L3.5 11.535L3.97585 9.7651L8.94262 11.0914L4.95434 7.11667L6.25439 5.82104L9.89043 9.4447L8.43062 4.01524L10.2066 3.54101L11.5577 8.5663L13.0542 3ZM15.1726 14.6796C15.6137 14.2178 15.9351 13.6415 16.0874 13L20.0457 16.9448L18.7456 18.2405L15.1726 14.6796ZM15.1365 14.7188C14.6901 15.1731 14.126 15.5122 13.4934 15.6869L14.7936 20.5226L16.5695 20.0484L15.1365 14.7188ZM12.5091 15.8193C12.8262 15.8193 13.1338 15.7793 13.4273 15.7042L12.0029 21.0018L10.2271 20.5276L11.5284 15.6875C11.8406 15.7734 12.1695 15.8193 12.5091 15.8193ZM11.4654 15.6661C10.8425 15.4827 10.2884 15.1393 9.85116 14.6836L6.26938 18.2532L7.56943 19.5489L11.4654 15.6661ZM8.92955 12.9961C9.07855 13.6277 9.3916 14.1963 9.82137 14.6549L4.45732 16.0873L3.98148 14.3174L8.92955 12.9961Z"
        fill="url(#paint0_linear_14845_6159)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14845_6159"
          x1="3.56648"
          y1="12.0639"
          x2="21.5665"
          y2="12.0639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B75CFF" />
          <stop offset="1" stopColor="#ED4BAC" />
        </linearGradient>
      </defs>
    </>
  ),
})

AIAgentIcon.displayName = "AIAgentIcon"
export default AIAgentIcon
