import { css } from "@emotion/react"
import { getColor } from "@illa-design/react"

export const customDomainContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 600px;
`

export const domainTitleStyle = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: ${getColor("grayBlue", "02")};
  margin: 0;
`

export const brandingContentContainerStyle = css`
  padding: 32px 0px;
  display: flex;
  width: 100%;
  gap: 80px;
  justify-content: center;
`

export const browserPreviewContainerStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 600px;
`

export const tipStyle = css`
  color: ${getColor("grayBlue", "03")};
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`
