import { LoaderFunctionArgs, redirect } from "react-router-dom"
import { checkIfUserNeedsPasswordReset } from "@/services/auth"

export const checkNeedResetPwdLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const email = params.email
  try {
    const response = await checkIfUserNeedsPasswordReset(email)
    return {
      needResetPwd: response.data.doesUserNeedResetPassword,
    }
  } catch (e) {
    return redirect("/500")
  }
}
