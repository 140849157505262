import { USER_ROLE } from "@illa-public/public-types"
import { fetchTeamSubscription } from "@illa-public/upgrade-modal/service"
import { teamActions } from "@illa-public/user-data"
import { isBiggerThanTargetRole } from "@illa-public/user-role-utils"
import { defer, redirect } from "react-router-dom"
import { LoaderFunctionArgs } from "react-router-dom"
import { billingActions } from "@/redux/billing/billingSlice"
import { store } from "@/store"
import { getTeamsInfoLoader, getUserInfoLoader } from "./authLoader"

export const teamBillingLoader = async (args: LoaderFunctionArgs) => {
  const { params, request } = args
  const isLogin = await getUserInfoLoader()
  if (isLogin) {
    const { teamIdentifier } = params
    const teamList = await getTeamsInfoLoader()
    const team = teamList.find((team) => team.identifier === teamIdentifier)
    if (!team) {
      return redirect("/404")
    }
    store.dispatch(teamActions.updateCurrentIdReducer(team.id))
    const canAccess = isBiggerThanTargetRole(
      USER_ROLE.EDITOR,
      team.myRole,
      false,
    )
    if (!canAccess) {
      const redirectURL = new URL(request.url)
      return redirect(
        `/403?redirectURL=${encodeURIComponent(redirectURL.toString())}`,
      )
    }
    const response = fetchTeamSubscription(team.id).then((response) => {
      store.dispatch(billingActions.updateBillingInfoReducer(response.data))
      return response
    })
    return defer({
      billingInfo: response,
    })
  } else {
    return redirect("/login")
  }
}
