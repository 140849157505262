import { createIcon } from "@illa-design/react"

const GraphQLIcon = createIcon({
  title: "GraphQLIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="m2.585 17.662.863.498 9.609-16.643-.863-.498-9.609 16.643z"
        fill="#E535AB"
      />
      <path d="M21.606 16.332H2.388v.996h19.218v-.996z" fill="#E535AB" />
      <path
        d="m2.768 16.891 9.613 5.55.498-.862-9.613-5.55-.498.862zM11.119 2.427l9.612 5.55.498-.863-9.612-5.55-.498.863z"
        fill="#E535AB"
      />
      <path
        d="m2.771 7.11.498.863 9.613-5.55-.498-.862L2.77 7.11z"
        fill="#E535AB"
      />
      <path
        d="m10.945 1.517 9.61 16.643.862-.498-9.61-16.643-.862.498zM4.146 6.45H3.15v11.1h.996V6.45z"
        fill="#E535AB"
      />
      <path d="M20.85 6.45h-.996v11.1h.996V6.45z" fill="#E535AB" />
      <path
        d="m11.776 21.267.435.753 8.36-4.827-.434-.753-8.36 4.827z"
        fill="#E535AB"
      />
      <path
        d="M22.17 17.874a2.097 2.097 0 0 1-2.862.768 2.097 2.097 0 0 1-.768-2.862 2.097 2.097 0 0 1 2.862-.768 2.088 2.088 0 0 1 .768 2.862zM5.454 8.22a2.097 2.097 0 0 1-2.862.768 2.097 2.097 0 0 1-.768-2.862 2.097 2.097 0 0 1 2.862-.768 2.099 2.099 0 0 1 .768 2.862zM1.83 17.874a2.099 2.099 0 0 1 .768-2.862 2.099 2.099 0 0 1 2.862.768 2.096 2.096 0 0 1-3.63 2.094zM18.546 8.22a2.099 2.099 0 0 1 .768-2.862 2.099 2.099 0 0 1 2.862.768 2.099 2.099 0 0 1-.768 2.862 2.097 2.097 0 0 1-2.862-.768zM12 23.748a2.092 2.092 0 0 1-2.094-2.094c0-1.158.936-2.094 2.094-2.094s2.094.936 2.094 2.094A2.096 2.096 0 0 1 12 23.748zM12 4.44a2.092 2.092 0 0 1-2.094-2.094c0-1.158.936-2.094 2.094-2.094s2.094.936 2.094 2.094A2.092 2.092 0 0 1 12 4.44z"
        fill="#E535AB"
      />
    </>
  ),
})

GraphQLIcon.displayName = "GraphQLIcon"
export default GraphQLIcon
