import { FC, memo } from "react"
import { useTranslation } from "react-i18next"
import DomainForm from "../Components/DomainForm"
import BrowserPreview from "./Components/BrowserPreview"
import BrandingPageHeader from "./Components/Header"
import {
  brandingContentContainerStyle,
  browserPreviewContainerStyle,
  customDomainContainerStyle,
  domainTitleStyle,
  tipStyle,
} from "./style"

const PCCustomDomain: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <BrandingPageHeader />
      <div css={brandingContentContainerStyle}>
        <div css={customDomainContainerStyle}>
          <h2 css={domainTitleStyle}>
            {t("team_setting.branding.custom_domain.custom_domain")}
          </h2>
          <DomainForm />
        </div>
        <div css={browserPreviewContainerStyle}>
          <BrowserPreview />
          <p css={tipStyle}>
            {t("team_setting.branding.custom_domain.preview")}
          </p>
        </div>
      </div>
    </>
  )
}

export default memo(PCCustomDomain)
