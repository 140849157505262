import { removeAuthToken } from "@illa-public/utils"
import { AxiosError } from "axios"

export const errorHandlerInterceptor = (error: AxiosError) => {
  const { response, config } = error
  let duration = "0"
  if (config?.headers) {
    const start = config?.headers["Illa-Request-Start"] as string
    const end = performance.now()
    duration = (end - parseFloat(start ?? "0")).toString()
  }
  if (!response) {
    return Promise.reject(error)
  }
  response.headers["Illa-Request-Duration"] = duration

  const { status } = response
  switch (status) {
    case 401: {
      removeAuthToken()
      window.location.pathname = "/login"
      break
    }
    default: {
      // throw new Response(message, { status: status })
    }
  }
  return Promise.reject(error)
}
