import { InternalAxiosRequestConfig } from "axios"

export const durationStartInterceptor = (
  config: InternalAxiosRequestConfig,
) => {
  if (config.headers) {
    config.headers["Illa-Request-Start"] = performance.now()
  }
  return config
}
