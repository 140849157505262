import {
  CLOUD_REQUEST_PREFIX,
  HTTP_REQUEST_PUBLIC_BASE_URL,
} from "@illa-public/illa-net"
import { getAuthToken } from "@illa-public/utils"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ICustomDomain, ITeamConfig } from "./interface"

export const supervisorAPI = createApi({
  reducerPath: "supervisorAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: `${HTTP_REQUEST_PUBLIC_BASE_URL}${CLOUD_REQUEST_PREFIX}`,
    prepareHeaders: (headers) => {
      const token = getAuthToken()
      if (token) {
        headers.set("Authorization", token)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCustomDomain: builder.query<ICustomDomain, { teamID: string }>({
      query: ({ teamID }) => `/teams/${teamID}/domain`,
    }),
    setCustomDomain: builder.mutation<
      ICustomDomain,
      { teamID: string; domain: string }
    >({
      query: ({ teamID, domain }) => ({
        url: `/teams/${teamID}/domain`,
        method: "PATCH",
        body: { customDomain: domain },
      }),
    }),
    deleteCustomDomain: builder.mutation<
      ICustomDomain,
      { teamID: string; domain: string }
    >({
      query: ({ teamID, domain }) => ({
        url: `/teams/${teamID}/domain`,
        method: "DELETE",
        body: { customDomain: domain },
      }),
    }),
    validateCustomDomain: builder.mutation<
      void,
      { teamID: string; domain: string }
    >({
      query: ({ teamID, domain }) => ({
        url: `/teams/${teamID}/domain/resolveResult`,
        method: "POST",
        body: { customDomain: domain },
      }),
    }),
    getTeamUploadFaviconURL: builder.mutation<
      {
        uploadAddress: string
      },
      { teamID: string; fileName: string; fileType: string }
    >({
      query: ({ teamID, fileName, fileType }) =>
        `/teams/${teamID}/favicon/uploadAddress/fileName/${fileName}.${fileType}`,
    }),
    updateTeamConfig: builder.mutation<
      void,
      {
        teamID: string
        config: Partial<ITeamConfig>
      }
    >({
      query: ({ teamID, config }) => ({
        url: `/teams/${teamID}/config`,
        method: "PATCH",
        body: config,
      }),
    }),
  }),
})

export const {
  useGetCustomDomainQuery,
  useSetCustomDomainMutation,
  useDeleteCustomDomainMutation,
  useValidateCustomDomainMutation,
  useGetTeamUploadFaviconURLMutation,
  useUpdateTeamConfigMutation,
} = supervisorAPI
