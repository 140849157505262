import { createIcon } from "@illa-design/react"

const RedisIcon = createIcon({
  title: "RedisIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <g clipPath="url(#f3k8oj0zea)">
        <path
          d="M23.543 17.692c-1.308.682-8.082 3.467-9.524 4.219-1.442.752-2.243.745-3.382.2-1.14-.544-8.348-3.456-9.646-4.077-.65-.31-.99-.572-.99-.82v-2.476s9.384-2.043 10.9-2.586c1.514-.544 2.04-.562 3.329-.091 1.29.47 8.999 1.863 10.273 2.33v2.441c0 .245-.295.514-.96.86z"
          fill="#A41E11"
        />
        <path
          d="M23.543 15.215c-1.308.681-8.082 3.467-9.524 4.218-1.442.752-2.243.745-3.382.2-1.14-.544-8.348-3.456-9.646-4.076-1.299-.62-1.326-1.048-.05-1.548l9.96-3.856c1.514-.543 2.04-.561 3.33-.09 1.288.47 8.022 3.152 9.296 3.619 1.274.466 1.323.851.014 1.533h.002z"
          fill="#D82C20"
        />
        <path
          d="M23.543 13.655c-1.308.682-8.082 3.467-9.524 4.22-1.442.751-2.243.744-3.382.199-1.14-.545-8.348-3.456-9.646-4.077-.65-.31-.99-.572-.99-.819v-2.477s9.384-2.043 10.9-2.587c1.514-.543 2.04-.561 3.329-.09 1.29.47 8.999 1.863 10.273 2.33v2.441c0 .245-.295.514-.96.86z"
          fill="#A41E11"
        />
        <path
          d="M23.543 11.176c-1.308.682-8.082 3.467-9.524 4.22-1.442.751-2.243.744-3.382.199-1.14-.545-8.348-3.457-9.646-4.077-1.299-.62-1.326-1.048-.05-1.547l9.96-3.856c1.514-.544 2.04-.562 3.33-.09 1.288.47 8.022 3.151 9.296 3.618 1.274.467 1.323.852.014 1.534h.002z"
          fill="#D82C20"
        />
        <path
          d="M23.543 9.466c-1.308.681-8.082 3.467-9.524 4.22-1.442.753-2.243.744-3.382.2-1.14-.545-8.348-3.457-9.646-4.077-.65-.31-.99-.572-.99-.82V6.512s9.384-2.043 10.9-2.586c1.514-.544 2.04-.562 3.329-.09 1.29.47 8.999 1.862 10.273 2.329v2.442c0 .244-.295.513-.96.86z"
          fill="#A41E11"
        />
        <path
          d="M23.543 6.988c-1.308.682-8.082 3.467-9.524 4.22-1.442.751-2.243.743-3.382.199C9.497 10.863 2.289 7.951.99 7.33-.308 6.71-.335 6.282.94 5.783l9.96-3.856c1.514-.544 2.04-.562 3.33-.09 1.288.47 8.022 3.151 9.296 3.618 1.274.467 1.323.852.014 1.533h.002z"
          fill="#D82C20"
        />
        <path
          d="m12.096 4.69-2.451-.22 1.829-.66-.547-1.012 1.714.67 1.613-.529-.436 1.047 1.646.617-2.123.22-.475 1.144-.77-1.276zM8.775 8.509l5.678-.872-1.714 2.515-3.964-1.643zM7.244 7.591c1.676 0 3.034-.527 3.034-1.176 0-.65-1.358-1.177-3.034-1.177-1.676 0-3.035.527-3.035 1.177S5.568 7.59 7.244 7.59z"
          fill="#fff"
        />
        <path d="m21.332 6.244-3.356 1.325V4.915l3.356 1.329z" fill="#7A0C00" />
        <path
          d="M17.973 4.916v2.655l-.365.144-3.356-1.325 3.72-1.474z"
          fill="#AD2115"
        />
      </g>
      <defs>
        <clipPath id="f3k8oj0zea">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
})

RedisIcon.displayName = "RedisIcon"
export default RedisIcon
