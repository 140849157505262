import { FC, memo } from "react"
import { useFormContext } from "react-hook-form"
import ILLALogo from "@/assets/public/illa-logo-purple.svg?react"
import { IDomainFormFiled } from "../../../interface"
import CloseIcon from "./assets/close.svg?react"
import CurveLIcon from "./assets/curveL.svg?react"
import CurveRIcon from "./assets/curveR.svg?react"
import SecureIcon from "./assets/secure.svg?react"
import {
  browserPreviewContainerStyle,
  controlGroupStyle,
  greenControlStyle,
  logoContainerStyle,
  metaIconStyle,
  redControlStyle,
  tabContainerStyle,
  tabTitleStyle,
  tableNameStyle,
  toolbarHeaderStyle,
  urlControlContainerStyle,
  urlControlStyle,
  urlStyle,
  yellowControlStyle,
} from "./style"

const BrowserPreview: FC = () => {
  const { getFieldState, watch } = useFormContext<IDomainFormFiled>()

  const customState = getFieldState("customDomain")

  const customDomain = watch("customDomain")
  const siteTitle = watch("siteTitle")
  const favicon = watch("favicon")

  return (
    <div css={browserPreviewContainerStyle}>
      <div css={toolbarHeaderStyle}>
        <div css={controlGroupStyle}>
          <div css={redControlStyle} />
          <div css={yellowControlStyle} />
          <div css={greenControlStyle} />
        </div>
        <div css={tabContainerStyle}>
          <CurveLIcon />
          <div css={tabTitleStyle}>
            <div css={metaIconStyle}>
              {favicon ? (
                <img src={favicon} width="100%" height="100%" />
              ) : (
                <div css={logoContainerStyle}>
                  <ILLALogo />
                </div>
              )}
            </div>
            <p css={tableNameStyle}>{siteTitle || "ILLA Cloud"}</p>
            <CloseIcon />
          </div>
          <CurveRIcon />
        </div>
      </div>
      <div css={urlControlContainerStyle}>
        <div css={urlControlStyle}>
          <SecureIcon />
          <p css={urlStyle}>
            {customState.error
              ? "cloud.illacloud.com"
              : customDomain || "cloud.illacloud.com"}
          </p>
        </div>
      </div>
    </div>
  )
}

export default memo(BrowserPreview)
