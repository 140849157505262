import createCache from "@emotion/cache"
import { CacheProvider, Global } from "@emotion/react"
import {
  ILLA_MIXPANEL_EVENT_TYPE,
  ILLA_MIXPANEL_PUBLIC_PAGE_NAME,
} from "@illa-public/mixpanel-utils"
import { UpgradeModalGroup } from "@illa-public/upgrade-modal"
import { getCurrentTranslateLanguage } from "@illa-public/user-data"
import { ConfigProvider } from "antd"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RouterProvider } from "react-router-dom"
import { MessageGroup, ModalGroup } from "@illa-design/react"
import defaultThemeToken from "@/assets/theme/default.json"
import { getLocalLanguage } from "@/i18n"
import { ILLARoute } from "@/router"
import { px2Rem } from "@/utils/stylis-plugin/px2rem"
import { globalStyles, messageStyle } from "./style"
import { track } from "./utils/mixpanelHelper"

const App: FC = () => {
  const currentLanguage =
    useSelector(getCurrentTranslateLanguage) || getLocalLanguage()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (currentLanguage && currentLanguage !== i18n.language) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useEffect(() => {
    track(
      ILLA_MIXPANEL_EVENT_TYPE.ILLA_ACTIVE,
      ILLA_MIXPANEL_PUBLIC_PAGE_NAME.PLACEHOLDER,
    )
  }, [])

  let cache = createCache({
    key: "css",
    stylisPlugins: [
      px2Rem({
        unit: "rem",
        remSize: 100,
      }),
    ],
  })

  return (
    <ConfigProvider theme={defaultThemeToken}>
      <CacheProvider value={cache}>
        <Global styles={globalStyles} />
        <MessageGroup _css={messageStyle} />
        <ModalGroup />
        <RouterProvider router={ILLARoute} />
        <UpgradeModalGroup />
      </CacheProvider>
    </ConfigProvider>
  )
}

export default App
