import {
  actionRuntimeAxios,
  authInterceptor,
  needAuthAxios,
  notNeedAuthAxios,
} from "@illa-public/illa-net"
import { durationStartInterceptor } from "./interceptors/request/durationStartInterceptor"
import { durationEndInterceptor } from "./interceptors/response/durationStartInterceptor"
import { errorHandlerInterceptor } from "./interceptors/response/errorHandler"

needAuthAxios.interceptors.request.use(authInterceptor)
needAuthAxios.interceptors.request.use(durationStartInterceptor)
needAuthAxios.interceptors.response.use(
  durationEndInterceptor,
  errorHandlerInterceptor,
)
actionRuntimeAxios.interceptors.request.use(authInterceptor)

notNeedAuthAxios.interceptors.request.use(durationStartInterceptor)
notNeedAuthAxios.interceptors.response.use(durationEndInterceptor)
