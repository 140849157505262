import { css } from "@emotion/react"
import { applyMobileStyle } from "@illa-public/utils"
import { getColor } from "@illa-design/react"

export const inputStyle = css`
  display: none;
`

export const uploadFavicon = css`
  width: 100%;
  display: flex;
  align-items: center;
`

export const uploadIconContainerStyle = css`
  padding: 10px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background-color: ${getColor("grayBlue", "09")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  ${applyMobileStyle(css`
    padding: 12.5px;
    width: 77px;
    height: 77px;
    font-size: 15px;
  `)}
`

export const faviconStyle = css`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  ${applyMobileStyle(css`
    width: 77px;
    height: 77px;
  `)}
`
