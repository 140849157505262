import { AxiosResponse } from "axios"

export const durationEndInterceptor = (response: AxiosResponse) => {
  if (response.config?.headers) {
    const start = response.config.headers["Illa-Request-Start"] as string
    const end = performance.now()
    const duration = end - parseFloat(start ?? "0")
    response.headers["Illa-Request-Duration"] = duration.toString()
  }
  return response
}
