import { createIcon } from "@illa-design/react"

const GlobalDataIcon = createIcon({
  title: "AIAgentIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <rect x="2.5" y="2" width="20" height="20" rx="3" fill="#FF58EE" />
      <path
        d="M14.7393 14.6312C14.9358 15.1022 15.1116 15.4209 15.2607 15.6135C15.3583 15.7395 15.4165 15.7767 15.4341 15.7863C15.534 15.7821 15.6197 15.7452 15.7104 15.6483C15.8202 15.5308 15.9404 15.3187 16.0439 14.9654L16.2028 14.4225L16.7158 14.6472C16.9617 14.7549 17.2655 14.9864 17.2655 15.4033C17.2655 15.6055 17.2009 15.8089 17.1222 15.9908C17.0401 16.1808 16.9235 16.3902 16.7797 16.6159C16.3264 17.3252 15.7321 17.8 14.9808 17.8C14.3985 17.8 13.9338 17.4664 13.5828 16.9741L13.5806 16.9711C13.2606 16.5152 12.8418 15.643 12.3276 14.4057C11.775 15.3811 11.2067 16.1583 10.6178 16.721C9.90181 17.4051 9.12433 17.8 8.2921 17.8C7.91836 17.8 7.53772 17.6981 7.15674 17.5207L6.5 17.2151L8.2748 15.4662L8.59486 15.6408C8.69764 15.6968 8.80647 15.7249 8.92911 15.7249C9.26173 15.7249 9.74662 15.4566 10.3673 14.5648L10.3684 14.5632C10.8917 13.8175 11.2793 13.1723 11.5386 12.6254L11.542 12.6182L11.5753 12.551L11.2412 11.7542L10.9844 11.1104C10.8993 10.8993 10.7933 10.6624 10.6664 10.4008L10.6624 10.3926C10.4909 10.0222 10.389 9.80185 10.3571 9.73262L10.3539 9.72562L10.3509 9.71851C10.2351 9.4457 10.1347 9.27817 10.0579 9.18682C10.044 9.17037 10.0326 9.15826 10.0237 9.14951C9.97892 9.16143 9.91397 9.1967 9.82673 9.29732C9.69742 9.44645 9.55269 9.70465 9.40907 10.109L9.25732 10.5363L8.82245 10.4261C8.66884 10.3872 8.50569 10.3032 8.38575 10.1438C8.26702 9.98594 8.23014 9.8066 8.23014 9.65072C8.23014 9.05053 8.51586 8.47211 8.97049 7.92649C9.41397 7.39425 9.93347 7 10.5284 7C11.0445 7 11.4415 7.31132 11.7287 7.73086C11.9979 8.12425 12.3572 8.88661 12.802 9.96517L12.9484 10.3219L13.1136 10.0265C13.6479 9.07818 14.2003 8.33432 14.7782 7.82255C15.3594 7.3079 15.9919 7.00686 16.6682 7.00686C17.0872 7.00686 17.487 7.09562 17.8619 7.27432L18.5 7.57851L16.7577 9.34335L16.429 9.15308C16.276 9.06447 16.1987 9.05448 16.1803 9.05448C15.9656 9.05448 15.6801 9.17174 15.3221 9.57473C14.9561 9.98666 14.4459 10.7735 13.7878 11.9743L13.6939 12.146L14.142 13.2352L14.4475 13.9366L14.7393 14.6312Z"
        fill="white"
      />
    </>
  ),
})

GlobalDataIcon.displayName = "GlobalDataIcon"
export default GlobalDataIcon
