import { TeamSubscription } from "@illa-public/upgrade-modal"
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit"

export const updateBillingInfoReducer: CaseReducer<
  TeamSubscription | null,
  PayloadAction<TeamSubscription>
> = (state, action) => {
  const { payload } = action
  return {
    ...state,
    ...payload,
  }
}
