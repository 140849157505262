import { ILLAMixpanel } from "@illa-public/mixpanel-utils"
import {
  currentUserActions,
  getCurrentUser,
  getTeamItems,
  teamActions,
} from "@illa-public/user-data"
import {
  getAuthToken,
  getILLACloudURL,
  sendConfigEvent,
} from "@illa-public/utils"
import { LoaderFunctionArgs, redirect } from "react-router-dom"
import { fetchCurrentUserTeamsInfo } from "@/services/team"
import { fetchUserInfo } from "@/services/user"
import { store } from "@/store"
import { getLocalCurrentTeamID, setLocalCurrentTeamID } from "@/utils/auth"

export const getUserInfoLoader = async () => {
  const userInfo = getCurrentUser(store.getState())
  if (!userInfo?.userID) {
    const token = getAuthToken()
    if (!token) {
      ILLAMixpanel.reset()
      return false
    } else {
      try {
        const { data } = await fetchUserInfo()
        sendConfigEvent(data?.userID)
        ILLAMixpanel.setUserID(data.userID)
        const reportedUserInfo: Record<string, any> = {}
        Object.entries(userInfo).forEach(([key, value]) => {
          reportedUserInfo[`illa_${key}`] = value
        })
        ILLAMixpanel.setUserProperties(reportedUserInfo)

        store.dispatch(currentUserActions.updateCurrentUserReducer(data))
        return true
      } catch (e) {
        ILLAMixpanel.reset()
        return false
      }
    }
  }
  return true
}

export const getTeamsInfoLoader = async () => {
  const userListInDisk = getTeamItems(store.getState())
  if (userListInDisk) {
    return userListInDisk
  }
  try {
    const response = await fetchCurrentUserTeamsInfo()
    store.dispatch(teamActions.updateTeamItemsReducer(response.data ?? []))
    return response.data ?? []
  } catch (e) {
    store.dispatch(teamActions.updateTeamItemsReducer([]))
    return []
  }
}

export const needAuthLoader = async (args: LoaderFunctionArgs) => {
  const { params } = args
  const isLogin = await getUserInfoLoader()
  if (isLogin) {
    const { teamIdentifier } = params
    const teamList = await getTeamsInfoLoader()
    if (teamIdentifier) {
      const team = teamList.find((team) => team.identifier === teamIdentifier)
      if (!team) {
        return redirect("/404")
      }
      ILLAMixpanel.setGroup(team.identifier)
      store.dispatch(teamActions.updateCurrentIdReducer(team.id))
    } else {
      let currentTeamID = getLocalCurrentTeamID()
      if (window.currentTeamIdentifier) {
        const currentTeamInfo = teamList.find(
          (team) => team.identifier === window.currentTeamIdentifier,
        )
        if (currentTeamInfo) {
          currentTeamID = currentTeamInfo.id
          setLocalCurrentTeamID(currentTeamInfo.id)
        } else {
          const redirectURL = `${getILLACloudURL()}?token=${getAuthToken()}`
          return redirect(
            `/403?error_flag=homepage.message.wrong_team&redirectURL=${encodeURIComponent(
              redirectURL,
            )}`,
          )
        }
      }
      const currentTeam = teamList.find((team) => team.id === currentTeamID)
      if (!currentTeam) {
        if (teamList.length > 0) {
          ILLAMixpanel.setGroup(teamList[0].identifier)
          store.dispatch(teamActions.updateCurrentIdReducer(teamList[0].id))
        }
      } else {
        ILLAMixpanel.setGroup(currentTeam.identifier)
        store.dispatch(teamActions.updateCurrentIdReducer(currentTeam.id))
      }
    }
  } else {
    return redirect("/login")
  }
  return null
}
